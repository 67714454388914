import React, { useEffect } from 'react'
import NavBar from '../components/NavBar/NavBar'
import Footer from '../components/Footer/Footer'
import { TypographyH1 } from '@/components/typography/TypographyH1'
import { Select, SelectItem, SelectContent, SelectGroup, SelectLabel, SelectTrigger, SelectValue  } from '@/components/ui/select'
import { Label } from '@/components/ui/label'
import { Checkbox } from '@/components/ui/checkbox'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible'
import usersService from '../services/users'
import { User, useUsersStore } from '../store/users'
import WeekWorkingHoursPicker from './components/WeekWorkingHoursPicker'
import { WorkingHours } from '../store/users'
import { CheckIcon, ChevronDownIcon, ChevronRightIcon, ReloadIcon } from '@radix-ui/react-icons'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { convertSecondsToTime, convertTimeToSeconds } from '@/utils/utils'






function Preferences() {

  
  const { user, setUser, error, setError, setUserPreferencesUpdated, userPreferencesUpdated } = useUsersStore();
  const [weekWorkingHours, setWeekWorkingHours] = React.useState<WorkingHours>();
  const [workingHoursOpen, setWorkingHoursOpen] = React.useState(false);
  const [minSegmentDuration, setMinSegmentDuration] = React.useState<number>(0);
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    usersService.getUserInfo().then((res: User) => {
      setUser(res);
      setWeekWorkingHours(res.preferences.workingHours);
      setMinSegmentDuration(res.preferences.minSegmentDuration);
    }).catch((err) => {
      console.log(err);
      setError(err.message);
    });


  }, [setError, setUser, userPreferencesUpdated]);

  const handleUpdateWeekWorkingHours = (workingHours: WorkingHours) => {
    setWeekWorkingHours(workingHours);
  }

  const handleUpdatePreferences = async () => {
    if(!user || !weekWorkingHours) {
      return;
    }
    setIsLoading(true);
    usersService.updateUserPreferences({
      ...user.preferences,
      workingHours: weekWorkingHours,
      minSegmentDuration: minSegmentDuration
    }).then(() => {
      setUserPreferencesUpdated();
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    });
  }

  return (
    <div className="pt-5 px-16 min-h-screen">
      <NavBar className="pb-20" />
        <TypographyH1>Preferences</TypographyH1>

        { 
        user && !error ? 
        
        (
        <div className="flex flex-col justify-between m-10">
          <div className="grid lg:grid-cols-8 grid-cols-4 items-center mt-5">
            <div className="">Theme</div>
            <Select disabled>
                <SelectTrigger>
                  <SelectValue placeholder="Light" />
                </SelectTrigger>
              <SelectGroup>
                <SelectContent>
                  <SelectLabel>Theme</SelectLabel>
                  <SelectItem value="light">Light</SelectItem>
                  <SelectItem value="dark">Dark</SelectItem>
                </SelectContent>
              </SelectGroup>
              
            </Select>
          </div>
          
          <div className="grid lg:grid-cols-8 grid-cols-4 items-start mt-5">
            <div className="">Notifications</div>
            <div className="flex flex-col col-span-3 gap-3">

              <div className="flex items-center gap-2">
                <Checkbox disabled/>
                <Label>Enable email notifications</Label>
              </div>
              <div className="flex items-center gap-2">
                <Checkbox disabled/>
                <Label>Enable push notifications</Label>
              </div>
            </div>
          </div>

          <div className="grid lg:grid-cols-8 grid-cols-4 items-center mt-5">
            <div className="">Minimum Segment Duration</div>
            <Input onChange={(event) => setMinSegmentDuration(convertTimeToSeconds(event.currentTarget.value))} placeholder={convertSecondsToTime(minSegmentDuration)} />
          </div>

          <div className=" mt-5">
            <Collapsible open={workingHoursOpen} onOpenChange={setWorkingHoursOpen}>
                  <CollapsibleTrigger>
                      <div className="mb-5 flex items-center"><span>Working Hours </span>{workingHoursOpen ? <ChevronDownIcon/> : <ChevronRightIcon />}</div>
                  </CollapsibleTrigger>
                  <CollapsibleContent>
                    <WeekWorkingHoursPicker user={user} onChange={handleUpdateWeekWorkingHours} />
                  </CollapsibleContent>
                </Collapsible>
          </div>
        </div>
        ) 
        : 
        (
          error ? 
          (
            <div className="m-20">
              <p className="text-2xl text-gray-500 text-center">{error}</p>
            </div>
          ) : 
          (
            <div className="m-20">
              <p className="text-2xl text-gray-500 text-center">Loading...</p>
            </div>
          )
        )
        }

        <div className="m-10">
          <Button onClick={handleUpdatePreferences}>
            {isLoading ? <ReloadIcon className="mr-2 h-4 w-4 animate-spin" /> : <CheckIcon className='mr-3'/>} Save
          </Button>
        </div>
    

      <Footer />
    </div>
  )
}

export default Preferences