
import useSchedulesStore, { Schedule, SortedTask } from '@/store/schedules'
import { convertSecondsToTime, convertTimeToSeconds } from '@/utils/utils'
import { CheckIcon, ChevronDownIcon, ChevronRightIcon, MagicWandIcon, ReloadIcon, TrashIcon } from '@radix-ui/react-icons';
import { Dialog, DialogContent, DialogTrigger } from '../../../../../../../components/ui/dialog';
import { useEffect, useState } from 'react';
import scheduleService from '@/services/schedules';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { DateTimePicker } from '@/components/ui/DateTimePicker';
import { Slider } from '@/components/ui/slider';
import { Button } from '@/components/ui/button';
import { DialogTitle } from '@radix-ui/react-dialog';
import DeleteTaskDialog from './DeleteTaskDialog';
import ShinyButton from '@/components/magicui/shiny-button';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import SubTasksInput from './SubTasksInput';


const formSchema = z.object({
  title: z.string().min(3),
  notes: z.string().default(''),
  category: z.string().default('uncategorized'),
  priority: z.coerce.number().min(1).max(10).default(5),
  timeEstimated: z.string(),
  deadline: z.date(),
  progress: z.coerce.number().min(0).max(100).default(0),
  paddingBefore: z.string().default('5m'),
  paddingAfter: z.string().default('5m'),
  subtasks: z.array(z.object({
    title: z.string(),
    done: z.boolean(),
  })),
}).refine((data) => {
  return data.deadline > new Date();
}, {
  message: 'Deadline must be in the future',
  path: ['deadline'],
});

function TaskCardDialog(props: {task?: SortedTask, color: string, schedule: Schedule, type: 'add' | 'edit', children: React.ReactNode}) {

  const {task, schedule, color, type} = props;
  const [bigCardOpen, setBigCardOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [advancedOpen, setAdvancedOpen] = useState(false);
  
  

  const {setTaskModified, setTaskCreated} = useSchedulesStore();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: type === 'edit' ? task?.title : undefined,
      notes: type=== 'edit' && task?.notes.length ? task?.notes : undefined,
      category: type=== 'edit' ? task?.category : undefined,
      priority: type=== 'edit' ? task?.priority : undefined,
      timeEstimated:  type=== 'edit' ? convertSecondsToTime(task?.timeEstimated) : undefined,
      deadline: type=== 'edit' ? new Date(task?.deadline || '') : new Date(schedule.period.end),
      progress: type=== 'edit' ? task?.progress : 0,
      paddingBefore: type=== 'edit' ? convertSecondsToTime(task?.padding?.before) : undefined,
      paddingAfter: type=== 'edit' ? convertSecondsToTime(task?.padding?.after) : undefined,
      subtasks: type=== 'edit' ? task?.subtasks || [] : [],
    },
  });

  useEffect(() => {
    if(type === 'edit') {
      form.setValue('title', task?.title || '');
      form.setValue('notes', task?.notes || '');
      form.setValue('category', task?.category || 'uncategorized');
      form.setValue('priority', task?.priority || 1);
      form.setValue('timeEstimated', convertSecondsToTime(task?.timeEstimated));
      form.setValue('deadline', new Date(task?.deadline || ''));
      form.setValue('progress', task?.progress || 0);
      form.setValue('paddingBefore', convertSecondsToTime(task?.padding?.before));
      form.setValue('paddingAfter', convertSecondsToTime(task?.padding?.after));
      form.setValue('subtasks', task?.subtasks || []);
    }
  }, [form, task, type]);

  

  const handleSave = async (data: z.infer<typeof formSchema>) => {
    const totalTasksEventsDuration = (schedule.tasks?.reduce((acc, task) => acc + task.timeEstimated, 0) ?? 0) + (schedule.events?.reduce((acc, event) => acc + (event.period.end.getTime() - event.period.start.getTime())/1000, 0) ?? 0) + convertTimeToSeconds(form.getValues('timeEstimated'));
    const scheduleFreeTime = await scheduleService.getScheduleFreeTime(schedule);
    console.log('totalTasksEventsDuration', totalTasksEventsDuration);
    console.log('scheduleFreeTime', scheduleFreeTime);
    if(totalTasksEventsDuration > scheduleFreeTime) {

      form.setError('timeEstimated', {
        type: 'manual',
        message: 'Not enough time in schedule to add this task. Please remove some tasks or events or increase Schedule period.'
      });
      return;
    }
    if(type === 'edit') {
      const taskData = {
        ...task,
        ...data,
        timeEstimated: convertTimeToSeconds(data.timeEstimated),
        padding: {
          before: convertTimeToSeconds(data.paddingBefore),
          after: convertTimeToSeconds(data.paddingAfter),
        }
      }
      setIsSaving(true);
      scheduleService.updateTask(taskData).then(() => {
        setIsSaving(false);
        setTaskModified();
      });
    } else {
      // add new task
      setIsSaving(true);
      scheduleService.addTask2Schedule(schedule, {
        ...data,
        timeEstimated: convertTimeToSeconds(data.timeEstimated),
        padding: {
          before: convertTimeToSeconds(data.paddingBefore),
          after: convertTimeToSeconds(data.paddingAfter),
        }
      }).then(() => {
        setIsSaving(false);
        setTaskCreated();
      });
    }

    setBigCardOpen(false);
    form.reset();
  }

  const handleEstimateTask = async () => {
    const title = form.getValues('title');
    const notes = form.getValues('notes');
    const subtasks = form.getValues('subtasks').map((subtask: {title: string, done: boolean}) => subtask.title);
    form.setValue('timeEstimated', 'estimating...');
    const estimation = await scheduleService.estimateTask(title, notes, subtasks).catch((error) => {
      form.setError('timeEstimated', {
        type: 'manual',
        message: error.response.data.errors,
      });
    });

    if(estimation) form.setValue('timeEstimated', convertSecondsToTime(estimation));


  }

  

  return (
    <Dialog open={bigCardOpen} onOpenChange={setBigCardOpen}>
      <DialogTrigger className='h-full w-full' >
        {props.children}
      </DialogTrigger>
      <DialogContent className={`${color} bg-gradient-to-br border-0 min-w-[60vw] min-h-[70vh] sm:rounded-3xl overflow-y-auto max-h-[80vh]`} onOpenAutoFocus={(e)=>e.preventDefault()}>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSave)}>
            <div className="flex flex-col justify-evenly h-[100%]">
              <DialogTitle>
                <FormField 
                  name='title'
                  control={form.control}
                  render={({field}) => (
                    <FormItem className="grid grid-cols-4 items-center gap-4">
                      <FormControl>
                        <Input id="name" placeholder="Your Task's title" className="
                        col-span-3 mt-4 bg-transparent ring-0 border-0 outline-0 focus-visible:outline-0 focus-visible:border-0 focus-visible:ring-0 focus-visible:ring-offset-0 text-3xl font-bold
                        " autoFocus={false} {...field}/>
                      </FormControl>
                      <FormMessage className='col-span-4' />
                    </FormItem>
                  )}
                />
              </DialogTitle>
              <div className='grid lg:grid-cols-2 grid-cols-1 '>
                <div className="col-span-1 flex flex-col justify-center gap-2 m-3">
                 
                  <FormField
                    name='notes'
                    control={form.control}
                    render={({field}) => (
                      <FormItem className="grid grid-cols-4 items-center gap-4">
                          
                        <FormControl autoFocus={true}>
                          <Textarea id="notes" rows={9} placeholder="Details and things to be reminded of..." className="
                          col-span-3 font-thin bg-transparent ring-0 border-0 outline-0 focus-visible:outline-0 focus-visible:border-0 focus-visible:ring-0 focus-visible:ring-offset-0 resize-none
                          " {...field}/>
                        </FormControl>
                        <FormMessage className='col-span-4' />
                      </FormItem>
                    )}
                  />

                  <FormField 
                    name='progress'
                    control={form.control}
                    render={({field}) => (
                      <FormItem className="grid grid-cols-4 items-center gap-4 w-full">
                        <FormLabel htmlFor="progress" className="text-left">
                          Progress
                        </FormLabel>
                        <FormControl className='flex gap-2 w-[260px]'>
                          <div className=''><Slider defaultValue={[field.value]} max={100} step={12.5} className='
                          w-[200px]
                          ' onChange={field.onChange} /> <span className='text-sm'>{field.value}%</span> </div>
                        </FormControl>
                        <FormMessage className='col-span-4' />
                      </FormItem>
                    )}
                  />  

                  <FormField 
                    name='timeEstimated'
                    control={form.control}
                    render={({field}) => (
                      <FormItem className="grid grid-cols-4 items-center gap-4 w-full">
                        <FormLabel htmlFor="timeEstimated" className="text-left col-span-1">
                          Duration:
                        </FormLabel>
                        <FormControl>
                          <div className="flex items-center  w-[80%] col-span-3">
                            <Input id="timeEstimated" placeholder="1h (possible units : s, m, h and d)" className="
                              bg-transparent w-full ring-0 font-thin border-0 outline-0 focus-visible:outline-0 focus-visible:border-0 focus-visible:ring-0 focus-visible:ring-offset-0
                            " {...field}/>
                            <ShinyButton onClick={()=>handleEstimateTask()} className='py-2 px-2 bg-white/20 disabled:border-0' type='button'><MagicWandIcon/></ShinyButton>
                          </div>
                        </FormControl>
                        <FormMessage className='col-span-4' />
                      </FormItem>
                    )}
                  />   
                  <FormField 
                    name='deadline'
                    control={form.control}
                    render={({field}) => (
                      <FormItem className="grid grid-cols-4 items-center gap-4">
                        <FormLabel htmlFor="deadline" className="text-left">
                          Deadline
                        </FormLabel>
                        <FormControl>
                          <DateTimePicker 
                            dateState={[form.watch('deadline'), field.onChange]} 
                            className='bg-transparent ring-0 font-thin border-0 outline-0 focus-visible:outline-0 focus-visible:border-0 focus-visible:ring-0 focus-visible:ring-offset-0'
                          />
                        </FormControl>
                        <FormMessage className='col-span-4' />
                      </FormItem>
                    )}
                  /> 
                </div>
                <div className='mt-3'>
                  <FormField
                    name='subtasks'
                    control={form.control}
                    render={({field}) => (
                      <FormItem>
                        <FormControl>
                          <SubTasksInput task={{
                            title: form.getValues('title'),
                            notes: form.getValues('notes'),
                          }} value={field.value} onChange={field.onChange} form={form} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>

                    )}
                  />
                  <Collapsible open={advancedOpen} onOpenChange={setAdvancedOpen}>
                    <CollapsibleTrigger className='flex gap-2 items-center'>
                        <div className="text-md font-light">Advanced </div>
                        { advancedOpen ? <ChevronDownIcon /> : <ChevronRightIcon /> }
                    </CollapsibleTrigger>
                    <CollapsibleContent className="col-span-1 flex flex-col justify-start ">
                      <FormField 
                        name='category'
                        control={form.control}
                        render={({field}) => (
                          <FormItem className="grid grid-cols-2 items-center gap-4">
                            <FormLabel htmlFor="category" className="text-left">
                              Category
                            </FormLabel>
                            <FormControl>
                              <Input id="category" placeholder="uncategorized" className="
                                bg-transparent ring-0 font-thin border-0 outline-0 focus-visible:outline-0 focus-visible:border-0 focus-visible:ring-0 focus-visible:ring-offset-0
                              " {...field}/>
                            </FormControl>
                            <FormMessage className='col-span-4' />
                          </FormItem>
                        )}
                      />
                      
                      
                      <FormField
                        name='priority'
                        control={form.control}
                        render={({field}) => (
                          <FormItem className="grid grid-cols-2 items-center gap-4">
                            <FormLabel htmlFor="priority" className="text-left">
                              Priority
                            </FormLabel>
                            <FormControl>
                              <Input id="priority" placeholder="From 1 to 10" className="
                                bg-transparent ring-0 font-thin border-0 outline-0 focus-visible:outline-0 focus-visible:border-0 focus-visible:ring-0 focus-visible:ring-offset-0
                              " {...field}/>
                            </FormControl>
                            <FormMessage className='col-span-4' />
                          </FormItem>
                        )}
                      />

                        <FormField 
                          name='paddingBefore'
                          control={form.control}
                          render={({field}) => (
                            <FormItem className="grid grid-cols-2 items-center gap-4">
                              <FormLabel htmlFor="padding-before" className="text-left">
                                Padding before:
                              </FormLabel>
                              <FormControl>
                                <Input id="padding-before" placeholder="5m" className='
                                bg-transparent ring-0 font-thin border-0 outline-0 focus-visible:outline-0 focus-visible:border-0 focus-visible:ring-0 focus-visible:ring-offset-0
                                ' {...field}/>
                              </FormControl>
                              <FormMessage className='col-span-4' />
                            </FormItem>
                          )}
                        />
                        <FormField 
                          name='paddingAfter'
                          control={form.control}
                          render={({field}) => (
                            <FormItem className="grid grid-cols-2 items-center gap-4">
                              <FormLabel htmlFor="padding-after" className="text-left">
                                Padding after:
                              </FormLabel>
                              <FormControl>
                                <Input id="padding-after" placeholder="5m" className='
                                bg-transparent ring-0 font-thin border-0 outline-0 focus-visible:outline-0 focus-visible:border-0 focus-visible:ring-0 focus-visible:ring-offset-0
                                ' {...field}/>
                              </FormControl>
                              <FormMessage className='col-span-4' />
                            </FormItem>
                          )}
                        />
                        
                      
                    </CollapsibleContent>
                  </Collapsible>
                </div>
                

              </div>
              <div className="flex flex-row justify-between gap-3">

              {task && (<DeleteTaskDialog schedule={schedule} task={task}>
                <Button className='rounded-full w-24 text-red-600 hover:text-white hover:bg-red-600' variant={'ghost'}>
                  <TrashIcon className='h-4 w-4'/> Delete
                </Button>
              </DeleteTaskDialog>)}
              <Button className='rounded-full w-24' variant={'secondary'} type='submit'>
                {isSaving ? <ReloadIcon className="mr-2 h-4 w-4 animate-spin" /> : <CheckIcon className='mr-3'/>} Save
              </Button>
              </div>
            </div>
            
          </form>
        </Form>
          
          
      </DialogContent>
    </Dialog>
  )
}

export default TaskCardDialog;