
import AuthAxios, { initAuthInAxios } from '../utils/axios';

import axios from 'axios';

const URL = import.meta.env.VITE_API_URL;

if(!URL) {
  throw new Error('VITE_API_URL is not defined');
}



class AuthService {
  async login(email: string, password: string) {
    const res = (await axios.post(`${URL}/auth/login`, {
      email,
      password
    })).data;

    return res;
  }

  initAuth(auth: { token: string, refreshToken: string }) {
    if(auth) {
      initAuthInAxios();
    }
  }

  async register(name: string, email: string, password: string, timezone: string) {
    return (await axios.post(`${URL}/auth/register`, {
      name,
      email,
      password,
      preferences: {
        timezone
      }
    })).data;
  }

  async checkToken() {
    const res = (await AuthAxios.post(`/auth/check`));
    if(res.status === 200) {
      return true;
    }
  }

  async refreshToken(refreshToken: string) {
    const res = (await AuthAxios.post(`/auth/refresh`, {}, {
      headers: {
        'X-REFRESH-TOKEN': refreshToken
      }
    })).data;

   

    return res;
    
  }

  async resendEmailConfirmation() {
    return (await AuthAxios.post(`/users/resendConfirmationEmail`)).data;
  }

  async confirmEmail(code: string) {
    return (await AuthAxios.post(`/users/confirmEmail?code=${code}`)).data;
  }

  async setOnboardingCompleted() {
    return (await AuthAxios.post('/users/completeOnboarding')).data;
  }

  
}

export default new AuthService();