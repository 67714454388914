import { useState } from 'react';
import NavBar from '../components/NavBar/NavBar';
import Footer from '../components/Footer/Footer';
import { TypographyH1 } from '@/components/typography/TypographyH1';

const HelpPage = () => {
  // State to handle opening/closing of FAQ answers
  const [open, setOpen] = useState<number>(-1);

  // Function to toggle FAQ
  const toggleFAQ = (index: number) => {
    setOpen(open === index ? -1 : index);
  };

  return (
    <div className="pt-5 px-16 min-h-screen">
      <NavBar className="pb-20" />
      <div className="container mx-auto py-8">
        <TypographyH1>Help & FAQs</TypographyH1>
        
        {/* Getting Started Section */}
        <div className="my-12">
          <h2 className="text-2xl font-semibold mb-4">Getting Started</h2>
          
          <div className="space-y-4">
            <div className="bg-white shadow-lg p-4 rounded-lg">
              <h3 className="text-xl font-bold mb-2">How to Register and Verify Your Account</h3>
              <p className="text-gray-600">
                To get started with Tamskirt, click on "Get Started" and fill in your details (name, email, and password). 
                You will then receive a verification code via email. If you don’t see it, check your spam folder or click "Resend." 
                Once verified, you can log in and begin planning.
              </p>
            </div>

            <div className="bg-white shadow-lg p-4 rounded-lg">
              <h3 className="text-xl font-bold mb-2">Creating Your First Schedule</h3>
              <p className="text-gray-600">
                After registering, go to the "Schedules" page and click "New Schedule." Give your schedule a name, 
                select start and end dates, and add any time blocks for fixed events like meetings or rest periods. 
                This will help the scheduler avoid scheduling tasks during these times.
              </p>
            </div>
          </div>
        </div>

        {/* Managing Tasks Section */}
        <div className="my-12">
          <h2 className="text-2xl font-semibold mb-4">Managing Tasks</h2>
          
          <div className="space-y-4">
            <div className="bg-white shadow-lg p-4 rounded-lg">
              <h3 className="text-xl font-bold mb-2">Adding and Customizing Tasks</h3>
              <p className="text-gray-600">
                To add tasks, go to your schedule and click "View Tasks." Hit "Add Task," 
                then enter the task details such as title, estimated time, and deadline. 
                You can also add subtasks, set progress, and use advanced options like task priority and padding for buffer time.
              </p>
            </div>

            <div className="bg-white shadow-lg p-4 rounded-lg">
              <h3 className="text-xl font-bold mb-2">How to Use the Task Estimator</h3>
              <p className="text-gray-600">
                When adding a task, you can click the "Magic" button to use Tamskirt's intelligent task estimator. 
                This feature automatically suggests an estimated time based on your task's details and past similar tasks.
              </p>
            </div>
          </div>
        </div>

        {/* Preferences Section */}
        <div className="my-12">
          <h2 className="text-2xl font-semibold mb-4">Preferences & Customization</h2>
          
          <div className="space-y-4">
            <div className="bg-white shadow-lg p-4 rounded-lg">
              <h3 className="text-xl font-bold mb-2">Adjusting Working Hours</h3>
              <p className="text-gray-600">
                You can customize your working hours from the "Preferences" page. 
                This allows you to define when tasks can be scheduled and will also affect your calendar view. 
                Any updates to your working hours will be applied to future tasks.
              </p>
            </div>

            <div className="bg-white shadow-lg p-4 rounded-lg">
              <h3 className="text-xl font-bold mb-2">Setting Minimum Segment Duration</h3>
              <p className="text-gray-600">
                By adjusting the minimum segment duration in your preferences, 
                you can prevent tasks from being broken into segments smaller than your chosen time frame. 
                For example, if set to 20 minutes, no task will be scheduled for less than 20 minutes at a time.
              </p>
            </div>
          </div>
        </div>

        {/* FAQ Section */}
        <div>
          <h2 className="text-2xl font-semibold mb-4">Frequently Asked Questions (FAQs)</h2>
          
          <div className="space-y-4">
            <div className="bg-white shadow-lg p-4 rounded-lg">
              <h3
                className="text-xl font-bold mb-2 cursor-pointer"
                onClick={() => toggleFAQ(1)}
              >
                How do I choose a focus for my schedule?
              </h3>
              {open === 1 && (
                <p className="text-gray-600">
                  When creating or editing a schedule, you can select a focus from the options provided, such as preventing missed deadlines 
                  or maximizing task efficiency. This helps Tamskirt prioritize tasks based on your specific goals.
                </p>
              )}
            </div>

            <div className="bg-white shadow-lg p-4 rounded-lg">
              <h3
                className="text-xl font-bold mb-2 cursor-pointer"
                onClick={() => toggleFAQ(2)}
              >
                Can I update my schedule after I’ve created it?
              </h3>
              {open === 2 && (
                <p className="text-gray-600">
                  Yes, you can always go back and edit your schedule by adding new tasks, adjusting time blocks, or modifying the focus. 
                  Tamskirt will automatically update the schedule and reschedule tasks accordingly.
                </p>
              )}
            </div>

            <div className="bg-white shadow-lg p-4 rounded-lg">
              <h3
                className="text-xl font-bold mb-2 cursor-pointer"
                onClick={() => toggleFAQ(3)}
              >
                How do I export my schedule to a calendar app?
              </h3>
              {open === 3 && (
                <p className="text-gray-600">
                  Once your schedule is complete, click the "Export" button to download it as an ICS file. 
                  You can then import this file into calendar apps like Google Calendar, Outlook, or Apple Calendar.
                </p>
              )}
            </div>

            <div className="bg-white shadow-lg p-4 rounded-lg">
              <h3
                className="text-xl font-bold mb-2 cursor-pointer"
                onClick={() => toggleFAQ(4)}
              >
                How do I report bugs or suggest features?
              </h3>
              {open === 4 && (
                <p className="text-gray-600">
                  If you encounter a bug or have a feature request, simply click on the "Report a Bug" or "Roadmap" button in the app. 
                  You can describe the issue, attach a screenshot, or suggest a feature. Your input helps us improve Tamskirt.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HelpPage;